import { Text } from '@sitecore-jss/sitecore-jss-react';
import LazyImage from 'src/components/Common/LazyImage';
import SvgLoader from 'src/components/Common/SvgLoader';
import { ThumbnailsItemProps } from '../types';

const ThumbnailsItem = ({ text, thumb, clickHandler }: ThumbnailsItemProps) => {
  return (
    <li className="group flex flex-shrink-0 justify-center relative mb-12 ml-12 first:ml-0">
      <button
        className="js-thumbnail items-center flex justify-center relative"
        onClick={clickHandler}
      >
        <LazyImage
          {...thumb}
          alt={`${text?.value || ''} Video Thumbnail`}
          className="inline opacity-80 group-hover:opacity-100 w-122 h-76 rounded-md"
        />
        <SvgLoader
          name="Play"
          color="text-white"
          size={36}
          className="absolute hidden group-hover:block"
          aria-hidden={true}
          focusable={false}
        />
      </button>
      {text?.value && (
        <div
          className="js-toolip hidden md:flex opacity-0 group-hover:opacity-100 bg-white justify-center pointer-events-none absolute z-dropdown shadow-md rounded-md text-gray-dark text-xs p-24 w-190 transition-opacity duration-300"
          aria-hidden="true"
          style={{ bottom: '90%' }}
        >
          <Text field={text} tag="p" />
          <SvgLoader
            name="Triangle"
            color="text-white"
            size={16}
            rotate={180}
            className="absolute bottom-0 -mb-12"
            aria-hidden={true}
            focusable={false}
          />
        </div>
      )}
    </li>
  );
};

export default ThumbnailsItem;
